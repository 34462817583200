<template>
  <div>
    <div class="company-map">
      <div class="address-maps">
        <div class="address-title" style="margin-top: 0">中译文娱科技有限公司</div>
        <div class="map-img">
          <div class="left-img">
            <img @click="go('map', 'qd')" src="@/assets/image/aboutus/map1.png" alt="" />
          </div>
          <div class="right-info">
            <div class="right-info-item" v-for="(qitem, qi) in mapInfo.qd" :key="qi">
              <div class="info-title">{{ qitem.name }}:</div>
              <div class="info-content">
                {{ qitem.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="address-title">中译文娱科技有限公司北京分公司</div>
        <div class="map-img">
          <div class="left-img">
            <img @click="go('map', 'bj')" src="@/assets/image/aboutus/bj.png" alt="" />
          </div>
          <div class="right-info">
            <div class="right-info-item" v-for="(qitem, qi) in mapInfo.bj" :key="qi">
              <div class="info-title">{{ qitem.name }}:</div>
              <div class="info-content">
                {{ qitem.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us">
      <div class="left-table">
        <div class="table-title">留下信息我们联系您</div>
        <div class="join-us-table">
          <div class="" style="width: 480px">
            <el-form :model="tableInfo" status-icon ref="ruleForm" class="demo-ruleForm">
              <el-form-item prop="name" :rules="[{ required: true, message: '姓名不能为空', trigger: 'blur' }]">
                <el-input placeholder="请输入姓名" v-model="tableInfo.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item prop="phone" :rules="[
              {
                required: true,
                message: '联系方式不能为空',
                trigger: 'blur',
              },
              {
                min: 11,
                max: 11,
                message: '请输入正确的联系方式',
                trigger: ['blur'],
              },
            ]">
                <el-input v-model="tableInfo.phone" placeholder="请输入联系方式" maxlength="11" clearable
                  οninput="value=value.replace(/[^\d]/g,'');if(value.length > 11)value = value.slice(0, 11)"></el-input>
              </el-form-item>
              <el-form-item prop="email" :rules="[
              {
                required: true,
                message: '邮箱地址不能为空',
                trigger: 'blur',
              },
              {
                type: 'email',
                message: '请输入正确的邮箱地址',
                trigger: ['blur', 'change'],
              },
            ]">
                <el-input placeholder="请输入邮箱" v-model="tableInfo.email"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input placeholder="请输入您的需求" v-model="tableInfo.req"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="btn-center">
                  <el-button @click="submitForm('ruleForm')">提 交</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- <div class="right-img">
        <img src="@/assets/image/aboutus/tscb.png" alt="" />
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mapInfo: {
        qd: [
          {
            name: '联系人',
            value: '王锴',
          },
          {
            name: '电话',
            value: '18766257116',
          },
          {
            name: '邮箱',
            value: 'wangkai@gtcom.com.cn',
          },
          // {
          //   name: "网址",
          //   value: "www.yeefuntv.com",
          // },
          {
            name: '地址',
            value: '山东省青岛市崂山区石岭路39号名汇国际2号楼2907',
          },
        ],
        bj: [
          {
            name: '联系人',
            value: '王锴',
          },
          {
            name: '电话',
            value: '18766257116',
          },
          {
            name: '邮箱',
            value: 'wangkai@gtcom.com.cn',
          },
          // {
          //   name: "网址",
          //   value: "www.yeefuntv.com",
          // },
          {
            name: '地址',
            value: '北京市石景山区五一剧场南路6号院1号楼1层',
          },
        ],
      },
      tableInfo: {
        name: '',
        email: '',
        phone: '',
        req: '',
      },
    };
  },
  methods: {
    go(type, key) {
      // type map 地图； company 公司详情
      if (type == 'map') {
        const locations = {
          qd:
            'https://j.map.baidu.com/80/Qnpi',
          bj:
            'https://j.map.baidu.com/70/YF6i',
        };
        key && window.open(locations[key]);
      }
      if (type == 'company') {
        this.$router.push({ path: '/company' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-button {
  cursor: pointer;
  width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #af406c;
  color: #fff;
  border-radius: 4px;
}

.company-map {
  // margin-top: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.address-title {
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 20px;
  margin-top: 40px;
}

.map-img {
  // width: 1200px;
  background: #e6ecf6;
  height: 300px;
  position: relative;
}

.left-mg {
  height: 300px;
}

.right-info {
  position: absolute;
  top: 0;
  right: 0;
  height: 300px;
  width: 410px;
  background-color: #e6ecf6;
  box-sizing: border-box;
  padding: 40px 50px;
}

.right-info-item {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  display: flex;
}

.info-title {
  width: 60px;
}

.contact-us {
  @extend .f;
  justify-content: center;
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 460px;

}

.right-img {
  img {
    position: absolute;
    right: 90px;
    bottom: 0px;
    // width: 520px;
    height: 470px;
  }
}

.left-table {
  // margin-left: 138px;
}

.table-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #333333;
}

.module-imgs {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.module-img-item {
  margin-bottom: 30px;
  width: 380px;
  height: 250px;
}

.join-us-table {
  margin-top: 30px;
  display: flex;

  // justify-content: center;
  .el-button {
    width: 100%;
    background: #0c173b;
    color: #fff;
  }
}

.btn-center {
  display: flex;
  justify-content: center;
}

.left-img {
  height: 100%;

  img {
    height: 100%;
  }
}
</style>
